<template>
  <a
    :href="hrefLink"
    target="_blank"
    @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
    @mouseleave="removeCursorStyle"
  >
    <img :src="imageSrc" class="w-10" :alt="alt" />
  </a>
</template>

<script>
export default {
  name: "IconLink",
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    hrefLink: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>
