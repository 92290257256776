let cursorMixin = {
  data() {
    return {
      cursorHoverButtonStyle: {
        wrapper: {
          display: "fixed",
          width: "120px",
          height: "120px",
        },
        dot: {
          display: "fixed",
          width: "10px",
          height: "10px",
        },
      },
      cursorHoverIconStyle: {
        wrapper: {
          borderRadius: "0px",
          display: "fixed",
          width: "48px",
          height: "48px",
        },
        dot: {
          borderRadius: "0px",
          display: "fixed",
          width: "12px",
          height: "12px",
        },
      },
    };
  },
  methods: {
    changeCursorStyle(style) {
      this.$root.$emit("change-cursor-style", style);
    },
    hideCursor() {
      this.changeCursorStyle({
        dot: {
          display: "none",
        },
        wrapper: {
          display: "none",
        },
      });
    },
    removeCursorStyle() {
      this.changeCursorStyle({
        dot: {},
        wrapper: {},
      });
    },
  },
};

export default cursorMixin;
