import { render, staticRenderFns } from "./RandomText.vue?vue&type=template&id=539e684a&scoped=true"
import script from "./RandomText.vue?vue&type=script&lang=js"
export * from "./RandomText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539e684a",
  null
  
)

export default component.exports