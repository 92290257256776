<template>
  <div
    class="h-full grid grid-cols-3 sm:grid-cols-2 sm:divide-x-4 sm:divide-tertiary"
  >
    <div
      @mouseenter="hideCursor"
      @mouseleave="removeCursorStyle"
      class="overflow-hidden font-extrabold text-[16px]/tight tracking-tighter cursor-none bg-clip-text text-transparent bg-[url('/src/assets/img/profile_pic.png')] bg-fixed bg-center bg-contain bg-no-repeat sm:col-span-1 col-span-3 hidden sm:block"
    >
      <RandomText />
    </div>
    <div id="room" class="relative bg-tertiary col-span-3 sm:col-span-1">
      <div
        id="hidden-icons"
        class="text-white flex flex-col justify-center gap-8 items-center absolute sm:left-[70%] left-[75%] inset-0 overflow-hidden"
      >
        <template v-for="(item, i) in menuItems">
          <div
            :key="`menuItem-${i}`"
            @mouseenter="changeCursorStyle(cursorHoverButtonStyle)"
            @mouseleave="removeCursorStyle"
            @click="switchTab(item.name)"
          >
            <DrawOutlineButton> {{ item.name }}</DrawOutlineButton>
          </div>
        </template>
      </div>
      <div style="perspective: 2500px" class="h-full">
        <!-- Start Section Home -->
        <!-- transform: rotateY(100deg); -->
        <div
          id="Home"
          style="
            transform-origin: left center;
            backface-visibility: hidden;
            transform-style: preserve-3d;
          "
          class="bg-background1 px-2 py-8 space-y-5 flex flex-col justify-center h-full drop-shadow-2xl filter-none text-primary"
        >
          <h1 class="text-6xl tracking-wide font-sans font-bold">
            <MagicSentence
              class="mb-2"
              :message="user.firstName"
              type="type1"
              apply="char"
            />

            <span
              class="relative after:content-[''] after:left-0 after:bottom-1 after:absolute after:w-full after:h-2 after:rounded-2xl after:bg-background2"
            >
              <MagicSentence
                class="mb-2 inline"
                :message="user.lastName"
                type="type1"
                apply="char"
              />
            </span>
          </h1>
          <h2 class="capitalize text-2xl text-wrap font-mono text-tertiary">
            I'm
            <span class="capitalize underline-offset-4 font-semibold">
              <TypedText :sentences="user.professions" />
            </span>
          </h2>
          <div class="flex *:text-primary *:duration-300 *:w-6 gap-4">
            <a
              @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
              @mouseleave="removeCursorStyle"
              href="mailto: tanishqlearn@gmail.com"
              class="hover:scale-125 group/email"
              :aria-label="`let's connect on mail`"
            >
              <Icon
                class="group-hover/email:hidden"
                icon="line-md:email-twotone"
                height="100%"
              />
              <Icon
                class="group-hover/email:block hidden"
                icon="line-md:email-opened-twotone-alt"
                height="100%"
              />
            </a>
            <a
              @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
              @mouseleave="removeCursorStyle"
              href="https://www.upwork.com/freelancers/~01040c44ce1cf20b77"
              target="_blank"
              class="hover:scale-125 link"
              :aria-label="`let's connect on upwork`"
            >
              <Icon icon="tabler:brand-upwork" height="100%" />
            </a>
            <a
              @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
              @mouseleave="removeCursorStyle"
              href="https://www.linkedin.com/in/tanishq-rocks"
              target="_blank"
              class="hover:scale-125"
              :aria-label="`let's connect on linkedin`"
            >
              <Icon icon="line-md:linkedin" height="100%" />
            </a>
            <a
              @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
              @mouseleave="removeCursorStyle"
              href="https://github.com/tanishq-rocks"
              target="_blank"
              class="hover:scale-125 group/github"
              :aria-label="`let's connect on github`"
            >
              <Icon
                class="group-hover/github:hidden"
                icon="line-md:github"
                height="100%"
              />
              <Icon
                class="group-hover/github:block hidden"
                icon="line-md:github-loop"
                height="100%"
              />
            </a>
          </div>
          <div>
            <EncryptButton
              @mouseenter.native="changeCursorStyle(cursorHoverButtonStyle)"
              @mouseleave.native="removeCursorStyle"
              class="hover:scale-105 active:scale-95 rounded-md border-[1px] border-tertiary bg-tertiary px-4 py-2 font-mono font-medium text-background2 transition-colors duration-300"
              text="Let's connect"
              :cyclePerLetter="2"
              :intervalTime="50"
              @click.native="switchTab('Contact')"
            />
          </div>
          <!-- <div
                  style="
                    backface-visibility: hidden;
                    transform: rotateY(180deg) translateZ(-2px);
                    transform-origin: center;
                  "
                  class="bg-[hsla(0,_100%,_100%,_0.9)] absolute inset-0 border border-blue-500"
                ></div> -->
          <button
            @mouseenter="changeCursorStyle(cursorHoverButtonStyle)"
            @mouseleave="removeCursorStyle"
            @click="toggleMenu"
            class="hover:scale-125 absolute w-10 -translate-y-[50%] transition-all duration-300 right-2 text-tertiary"
            aria-label="toggle menu"
          >
            <Icon icon="icon-park-twotone:electronic-door-lock" width="100%" />
          </button>
        </div>
        <!-- End Setion Home -->

        <!-- Start Section Skill -->
        <!-- transform: rotateY(100deg); -->
        <div
          id="Skills"
          style="
            transform-origin: left center;
            backface-visibility: hidden;
            transform-style: preserve-3d;
          "
          class="absolute z-10 inset-0 bg-background1 px-2 py-8 space-y-6 flex flex-col justify-center h-full drop-shadow-2xl filter-none text-primary"
        >
          <div>
            <p
              class="uppercase tracking-wider text-primary text-xs font-bold font-mono"
            >
              Skills
            </p>
            <h1 class="text-6xl tracking-wide font-sans font-bold text-primary">
              My Skills
            </h1>
            <h2 class="mt-3 text-tertiary font-light font-mono break-all">
              I like to take responsibility to craft aesthetic user experience
              using modern frontend architecture.
            </h2>
          </div>
          <div>
            <h3
              class="uppercase tracking-widest font-mono font-semibold mb-2 text-tertiary text-lg"
            >
              Languages and tools
            </h3>
            <div class="flex items-center flex-wrap gap-3">
              <template v-for="(item, i) in user.skills.languages">
                <IconLink
                  :key="item.alt"
                  :imageSrc="item.imageSrc"
                  :hrefLink="item.hrefLink"
                  :alt="item.alt"
                />
              </template>
              <template v-for="(item, i) in user.skills.tools">
                <IconLink
                  :key="item.alt"
                  :imageSrc="item.imageSrc"
                  :hrefLink="item.hrefLink"
                  :alt="item.alt"
                />
              </template>
            </div>
          </div>
          <div>
            <h3
              class="uppercase tracking-widest font-mono font-semibold mb-2 text-tertiary text-lg"
            >
              Libraries and frameworks
            </h3>
            <div class="flex items-center flex-wrap gap-3">
              <template v-for="(item, i) in user.skills.frameworks">
                <IconLink
                  :key="item.alt"
                  :imageSrc="item.imageSrc"
                  :hrefLink="item.hrefLink"
                  :alt="item.alt"
                />
              </template>
            </div>
          </div>
          <div>
            <h3
              class="uppercase tracking-widest font-mono font-semibold mb-2 text-tertiary text-lg"
            >
              Databases
            </h3>
            <div class="flex items-center flex-wrap gap-3">
              <template v-for="(item, i) in user.skills.databases">
                <IconLink
                  :key="item.alt"
                  :imageSrc="item.imageSrc"
                  :hrefLink="item.hrefLink"
                  :alt="item.alt"
                />
              </template>
            </div>
          </div>
          <button
            @mouseenter="changeCursorStyle(cursorHoverButtonStyle)"
            @mouseleave="removeCursorStyle"
            @click="toggleMenu"
            class="hover:scale-125 absolute w-10 -translate-y-[50%] transition-all duration-300 right-2 text-tertiary"
            aria-label="toggle menu"
          >
            <Icon icon="icon-park-twotone:electronic-door-lock" width="100%" />
          </button>
        </div>
        <!-- End Section Skill -->

        <!-- Start Section Work -->
        <!-- transform: rotateY(100deg); -->
        <div
          id="Work"
          style="
            transform-origin: left center;
            backface-visibility: hidden;
            transform-style: preserve-3d;
          "
          class="absolute z-10 inset-0 bg-background1 px-2 py-8 space-y-6 flex flex-col justify-center h-full drop-shadow-2xl filter-none text-primary"
        >
          <div>
            <p
              class="uppercase tracking-wider text-primary text-xs font-bold font-mono"
            >
              work
            </p>
            <h1 class="text-6xl tracking-wide font-sans font-bold text-primary">
              Experience
            </h1>
            <h2 class="mt-3 text-tertiary font-light font-mono break-all">
              I am a senior full-stack web developer with over 5 years of
              experience in Vue.js, Nuxt, Vuetify, Node.js, Express.js. My
              expertise encompasses a wide range of technologies, allowing me to
              architect and develop robust web solutions across the entire
              stack.
            </h2>
          </div>
          <div class="overflow-y-scroll overflow-x-auto">
            <div class="ml-4 space-y-6">
              <Timeline>
                <template #heading> About me </template>
                <template #icon>
                  <Icon icon="material-symbols:person" width="24" />
                </template>
                <template #subheading>
                  {{ user.firstName }} {{ user.lastName }}
                </template>
                <template #timePeriod>
                  Debugging since {{ user.dob }}
                </template>
                <template #location>
                  {{ user.address }}
                </template>
                <template #content>
                  <div class="break-all font-mono font-light text-tertiary">
                    📄 Know more about my experiences
                    <span class="relative">
                      <a
                        @mouseenter="changeCursorStyle(cursorHoverIconStyle)"
                        @mouseleave="removeCursorStyle"
                        href="/pdf/resume.pdf"
                        download="Resume_TanishqPatni.pdf"
                        class="peer font-bold decoration-tertiary underline underline-offset-4 hover:font-bold"
                        >download CV
                      </a>
                      <span
                        class="peer-hover:block hidden absolute -right-7 top-0"
                      >
                        <Icon icon="material-symbols:download" width="24" />
                      </span>
                    </span>
                  </div>
                  <p class="break-all font-mono font-light text-tertiary">
                    ✨ Fun fact
                    <span class="font-bold">{{ user.funFact }}</span>
                  </p>
                </template>
              </Timeline>

              <Timeline>
                <template #heading>Education</template>
                <template #icon>
                  <Icon icon="maki:college" width="24" />
                </template>
                <template #subheading>
                  Bachelor of Technology, Computer Science
                </template>
                <template #timePeriod> Graduated - 2021 </template>
                <template #location>
                  Dr. Ambedkar Institute of Technology, Bangalore, India
                </template>
                <template #content>
                  <ul
                    role="list"
                    class="list-disc pl-5 break-all font-mono font-light text-tertiary"
                  >
                    <li class="">CGPA: 8.90 / 10</li>
                    <li class="">
                      2019 | Secured
                      <span class="font-bold">First Rank</span> among 50+ teams
                      | AIT_Hacks, InterCollege
                      <span class="font-bold">Hackathon</span>
                    </li>
                  </ul>
                </template>
              </Timeline>

              <div>
                <Timeline paddingBottom="pb-4">
                  <template #heading> Professional Experience </template>
                  <template #icon>
                    <Icon icon="material-symbols:work-sharp" width="24" />
                  </template>
                  <template #subheading> System Engineer </template>
                  <template #timePeriod> Oct 2021 - Set 2023 </template>
                  <template #location>
                    Tata Consultancy Services, India
                  </template>
                  <template #content>
                    <ul
                      role="list"
                      class="list-disc pl-5 break-all font-mono font-light text-tertiary"
                    >
                      <li>
                        Built efficient and accurate advanced
                        <span class="font-bold">auditing software</span>
                        with emphasis on speed, reusability and interactivity.
                        Reduced the labor cost of auditing by
                        <span class="font-bold">90%</span>
                      </li>
                      <li>
                        Devised a
                        <span class="font-bold">framework migration tool</span>
                        having rich and interactive UI (using Vue.js & node.js)
                        that enables seamless conversion between frameworks;
                        enhanced accuracy and efficiency of associates by
                        <span class="font-bold">15 fold</span>
                      </li>
                      <li>
                        <span class="font-bold"
                          >Led the development and maintenance</span
                        >
                        of software applications and ensured high-quality code
                        through implementing software testing and debugging
                        techniques using technology stack such as JS, TypeScript
                      </li>
                      <li>
                        <span class="font-bold">Trained</span> 8 team members on
                        <span class="font-bold"
                          >Vue.js frontend development</span
                        >
                        through Knowledge Transfer; achieving a 70% reduction in
                        project delivery period without compromising on code
                        quality
                      </li>
                    </ul>
                  </template>
                </Timeline>

                <Timeline>
                  <template #icon>
                    <Icon icon="material-symbols:work-sharp" width="24" />
                  </template>
                  <template #subheading> Full Stack Developer </template>
                  <template #timePeriod> Sep 2023 - Aug 2024 </template>
                  <template #location> Biztree, Canada </template>
                  <template #content>
                    <ul
                      role="list"
                      class="list-disc pl-5 break-all font-mono font-light text-tertiary"
                    >
                      <li>
                        Built efficient and accurate advanced
                        <span class="font-bold">Contact application</span>
                        with emphasis on best practices, reusability and
                        interactivity.
                      </li>
                    </ul>
                  </template>
                </Timeline>
              </div>
            </div>
          </div>

          <button
            @mouseenter="changeCursorStyle(cursorHoverButtonStyle)"
            @mouseleave="removeCursorStyle"
            @click="toggleMenu"
            class="hover:scale-125 absolute w-10 -translate-y-[50%] transition-all duration-300 right-2 text-tertiary"
            aria-label="toggle menu"
          >
            <Icon icon="icon-park-twotone:electronic-door-lock" width="100%" />
          </button>
        </div>
        <!-- End Section Work -->

        <!-- Start Section Contact -->
        <!-- transform: rotateY(100deg); -->
        <div
          id="Contact"
          style="
            transform-origin: left center;
            backface-visibility: hidden;
            transform-style: preserve-3d;
          "
          class="absolute z-10 inset-0 bg-background1 px-2 py-8 space-y-6 flex flex-col justify-center h-full drop-shadow-2xl filter-none text-primary"
        >
          <div>
            <p
              class="uppercase tracking-wider text-primary text-xs font-bold font-mono"
            >
              contact
            </p>
            <h1 class="text-6xl tracking-wide font-sans font-bold text-primary">
              Contact
            </h1>
          </div>
          <div>
            <h1
              class="uppercase tracking-widest font-mono font-semibold mb-2 text-tertiary text-lg"
            >
              Get in Touch
            </h1>
            <div class="max-w-sm space-y-4">
              <div>
                <label
                  for="required-name"
                  class="text-tertiary font-mono font-light"
                >
                  Name
                </label>
                <input
                  v-model="visitor.name"
                  type="text"
                  id="required-name"
                  class="border border-background2 rounded-lg appearance-none w-full py-2 px-4 bg-white text-tertiary placeholder-gray-400 font-mono shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-tertiary focus:border-transparent"
                  name="required-name"
                  placeholder="Your name"
                />
              </div>
              <div>
                <label
                  for="required-email"
                  class="text-tertiary font-mono font-light"
                >
                  Email
                </label>
                <input
                  v-model="visitor.email"
                  type="text"
                  id="required-email"
                  class="border border-background2 rounded-lg appearance-none w-full py-2 px-4 bg-white text-tertiary placeholder-gray-400 font-mono shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-tertiary focus:border-transparent"
                  name="required-email"
                  placeholder="Your email"
                />
              </div>
              <div>
                <label
                  for="required-message"
                  class="text-tertiary font-mono font-light"
                >
                  Message
                </label>

                <textarea
                  v-model="visitor.message"
                  class="border border-background2 rounded-lg appearance-none w-full px-4 py-2 text-base text-tertiary placeholder-gray-400 font-mono bg-white focus:outline-none focus:ring-2 focus:ring-tertiary focus:border-transparent"
                  id="required-message"
                  placeholder="Enter your message"
                  name="required-message"
                  rows="5"
                  cols="40"
                >
                </textarea>
              </div>
              <div>
                <EncryptButton
                  @mouseenter.native="changeCursorStyle(cursorHoverButtonStyle)"
                  @mouseleave.native="removeCursorStyle"
                  class="hover:scale-105 active:scale-95 rounded-md border-[1px] border-tertiary bg-tertiary px-4 py-2 font-mono font-medium text-background2 transition-colors duration-300"
                  text="Send"
                  :cyclePerLetter="2"
                  :intervalTime="50"
                  @click.native="submitForm"
                />
              </div>
            </div>
          </div>
          <button
            @mouseenter="changeCursorStyle(cursorHoverButtonStyle)"
            @mouseleave="removeCursorStyle"
            @click="toggleMenu"
            class="hover:scale-125 absolute w-10 -translate-y-[50%] transition-all duration-300 right-2 text-tertiary"
            aria-label="toggle menu"
          >
            <Icon icon="icon-park-twotone:electronic-door-lock" width="100%" />
          </button>
        </div>
        <!-- End Section Contact -->
      </div>
    </div>
  </div>
</template>

<script>
import MagicSentence from "@/components/MagicSentence.vue";
import EncryptButton from "@/components/EncryptButton.vue";
import DrawOutlineButton from "@/components/DrawOutlineButton.vue";
import IconLink from "@/components/IconLink.vue";
import Timeline from "@/components/Timeline.vue";
import RandomText from "@/components/RandomText.vue";
import menuClickSound from "@/assets/sound/menu-click.mp3";
import itemClickSound from "@/assets/sound/item-click.mp3";

import TypedText from "@/components/TypedText.vue";
import { Icon } from "@iconify/vue2";
import emailjs from "@emailjs/browser";

import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);

export default {
  data() {
    return {
      user: {
        firstName: "Tanishq",
        lastName: "Patni",
        dob: "1999",
        address: "Baran, Rajasthan, India",
        funFact: "I like to play Chess",
        professions: [
          "full stack developer",
          "freelancer",
          "Developer",
          "Designer",
        ],
        skills: {
          languages: [
            {
              imageSrc: require("@/assets/icons/html.svg"),
              hrefLink: "https://www.w3.org/html/",
              alt: "html",
            },
            {
              imageSrc: require("@/assets/icons/css.svg"),
              hrefLink: "https://www.w3schools.com/css/",
              alt: "css",
            },
            {
              imageSrc: require("@/assets/icons/javascript.svg"),
              hrefLink: "https://developer.mozilla.org/en-US/docs",
              alt: "javascript",
            },
            {
              imageSrc: require("@/assets/icons/typescript.svg"),
              hrefLink: "https://www.typescriptlang.org/",
              alt: "typescript",
            },
            {
              imageSrc: require("@/assets/icons/sass.svg"),
              hrefLink: "https://sass-lang.com/",
              alt: "sass",
            },
          ],
          tools: [
            {
              imageSrc: require("@/assets/icons/node.svg"),
              hrefLink: "https://nodejs.org/",
              alt: "node",
            },
            {
              imageSrc: require("@/assets/icons/webpack.svg"),
              hrefLink: "https://webpack.js.org/",
              alt: "webpack",
            },
            {
              imageSrc: require("@/assets/icons/babel.svg"),
              hrefLink: "https://babeljs.io/",
              alt: "babel",
            },
            {
              imageSrc: require("@/assets/icons/git.svg"),
              hrefLink: "https://git-scm.com/",
              alt: "git",
            },
            {
              imageSrc: require("@/assets/icons/docker.svg"),
              hrefLink: "https://www.docker.com/",
              alt: "docker",
            },
          ],
          frameworks: [
            {
              imageSrc: require("@/assets/icons/vue.svg"),
              hrefLink: "https://vuejs.org/",
              alt: "vuejs",
            },
            {
              imageSrc: require("@/assets/icons/vuex.svg"),
              hrefLink: "https://vuex.vuejs.org/",
              alt: "vuex",
            },
            {
              imageSrc: require("@/assets/icons/nuxt.svg"),
              hrefLink: "https://v2.nuxt.com/",
              alt: "nuxt",
            },
            {
              imageSrc: require("@/assets/icons/vuetify.svg"),
              hrefLink: "https://vuetifyjs.com/en/",
              alt: "vuetify",
            },
            {
              imageSrc: require("@/assets/icons/quasar.svg"),
              hrefLink: "https://quasar.dev/",
              alt: "quasar",
            },
            {
              imageSrc: require("@/assets/icons/tailwind.svg"),
              hrefLink: "https://tailwindcss.com/",
              alt: "tailwind",
            },
            {
              imageSrc: require("@/assets/icons/bootstrap.svg"),
              hrefLink: "https://getbootstrap.com/",
              alt: "bootstrap",
            },
            {
              imageSrc: require("@/assets/icons/gsap.svg"),
              hrefLink: "https://gsap.com/",
              alt: "gsap",
            },
            {
              imageSrc: require("@/assets/icons/express.svg"),
              hrefLink: "https://expressjs.com/",
              alt: "express",
            },
          ],
          databases: [
            {
              imageSrc: require("@/assets/icons/mongodb.svg"),
              hrefLink: "https://www.mongodb.com/",
              alt: "mongodb",
            },
            {
              imageSrc: require("@/assets/icons/mysql.svg"),
              hrefLink: "https://www.mysql.com/",
              alt: "mysql",
            },
          ],
        },
      },
      menuClickAudio: new Audio(menuClickSound),
      itemClickAudio: new Audio(itemClickSound),
      tl: null,
      activeTab: "Home",
      visitor: {},
      menuItems: [
        {
          name: "Home",
        },
        {
          name: "Skills",
        },

        {
          name: "Work",
        },
        {
          name: "Contact",
        },
      ],
    };
  },
  components: {
    TypedText,
    MagicSentence,
    Icon,
    EncryptButton,
    DrawOutlineButton,
    RandomText,
    IconLink,
    Timeline,
  },
  mounted() {
    // console.log("mounted is being called");

    const existingTab = sessionStorage.getItem("activeTab");
    if (existingTab) {
      this.activeTab = existingTab;
    }
    this.menuItems
      .filter((item) => item.name !== this.activeTab)
      .map((item) => {
        document.getElementById(`${item.name}`).style.transform =
          "rotateY(100deg)";
      });
  },
  methods: {
    async toggleMenu() {
      // console.log("Toggle menu");
      if (this.tl) {
        await this.tl.reverse(1);
        this.tl = null;
        return;
      }
      this.menuClickAudio.play();
      this.tl = new gsap.timeline({
        duration: 0.3,
      });
      this.tl.to(`#room #${this.activeTab}`, {
        rotateY: "40deg",
        ease: "expo.out",
      });
      this.tl.fromTo(
        "#hidden-icons>div",
        {
          opacity: 0,
          x: 100,
          stagger: 0.3,
          zIndex: 0,
          ease: "linear",
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.1,
          zIndex: 10,
          ease: "bounce.out",
        }
      );
    },
    async switchTab(tabName) {
      // console.log("Switch tab");
      this.itemClickAudio.play();
      if (this.activeTab === tabName) {
        this.toggleMenu();
        return;
      }

      // Set tab value in sessionStorage
      sessionStorage.setItem("activeTab", tabName);

      await this.closeTab(this.activeTab);
      // console.log(`After Close - ${this.activeTab}`);
      // console.log(`Before Open - ${tabName}`);
      await this.openTab(tabName);
    },
    async openTab(tabName) {
      // console.log("Open tab", tabName);
      this.activeTab = tabName;
      await new Promise((resolve) => {
        let tl = new gsap.timeline({ onComplete: resolve });
        tl.to(`#${tabName}`, {
          rotateY: 0,
          duration: 2.5,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.37,0.985 0.38,1.315 0.455,1.315 0.489,1.315 0.527,1 0.544,0.954 0.562,0.902 0.588,0.756 0.638,0.802 0.679,0.84 0.701,1.094 0.747,1.116 0.794,1.139 0.811,0.876 0.859,0.944 0.907,1.011 0.895,1.025 0.927,1.025 0.951,1.025 0.961,0.975 0.969,0.975 0.984,0.975 1,1 1,1 "
          ),
        });
      });
    },
    async closeTab(tabName) {
      // console.log("Close tab", tabName);
      this.tl = null;
      await new Promise((resolve) => {
        let tl = new gsap.timeline({ onComplete: resolve });
        tl.to(`#${tabName}`, {
          rotateY: 100,
          duration: 1,
          ease: "power3.out",
        }).to(
          "#hidden-icons>div",
          {
            opacity: 0,
            x: 100,
            zIndex: 0,
          },
          "<"
        );
      });
    },
    async submitForm() {
      try {
        if (
          !(this.visitor.name && this.visitor.email && this.visitor.message)
        ) {
          throw new Error("EMPTY_FIELD_ERROR");
        }
        await emailjs.send(
          "contact_service",
          "contact_form",
          {
            user: this.visitor,
          },
          {
            publicKey: "L6mhJYzcKdAqM-xts",
          }
        );
        this.visitor = {};
        this.$root.$emit("show-toast", {
          variant: "success",
          icon: "line-md:confirm-circle",
          message: "Message sent successfully.",
          duration: 10,
        });
      } catch (error) {
        if (error.message === "EMPTY_FIELD_ERROR") {
          this.$root.$emit("show-toast", {
            variant: "warning",
            icon: "line-md:alert",
            message: "Fields cannot be empty.",
            duration: 5,
          });
          return;
        }
        this.$root.$emit("show-toast", {
          variant: "danger",
          icon: "line-md:alert",
          message: "Something went wrong.",
          duration: 5,
        });
      }
    },
  },
};
</script>
<style></style>
