<template>
  <button
    @mouseover="scramble"
    @mouseout="stopScramble"
    class="overflow-hidden cursor-none"
  >
    {{ buttonText }}
  </button>
</template>

<script>
name: "EncryptButton";
export default {
  props: {
    text: {
      type: String,
      default: "Encrypt Button",
    },
    cyclePerLetter: {
      type: Number,
      default: 2,
    },
    intervalTime: {
      type: Number,
      default: 50,
    },
    encryptionStringChars: {
      type: String,
      default: "!@#$%^&*():{};|,.<>/?",
    },
  },
  data() {
    return {
      intervalRef: null,
      buttonText: "",
    };
  },
  computed: {
    chars() {
      return this.encryptionStringChars;
    },
    targetText() {
      return this.text;
    },
  },
  mounted() {
    this.buttonText = this.text;
  },
  methods: {
    scramble() {
      {
        let pos = 0;

        this.intervalRef = setInterval(() => {
          const scrambled = this.targetText
            .split("")
            .map((char, index) => {
              if (pos / this.cyclePerLetter > index) {
                return char;
              }

              const randomCharIndex = Math.floor(
                Math.random() * this.chars.length
              );
              const randomChar = this.chars[randomCharIndex];

              return randomChar;
            })
            .join("");

          this.buttonText = scrambled;
          pos++;

          if (pos >= this.buttonText.length * this.cyclePerLetter) {
            this.stopScramble();
          }
        }, this.intervalTime);
      }
    },
    stopScramble() {
      clearInterval(this.intervalRef || undefined);

      this.buttonText = this.targetText;
    },
  },
};
</script>

<style scoped></style>
