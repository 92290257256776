<template>
  <div
    v-html="magicWord"
    :class="apply === 'word' ? 'flex flex-wrap justify-around' : ''"
  ></div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: () => {
        return "This is a message";
      },
    },
    type: {
      type: String,
      default: () => {
        return "type1";
      },
    },
    apply: {
      type: String,
      default: () => {
        return "char";
      },
    },
    magnify: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    magicWord() {
      let splitChar = "";
      if (this.apply.trim().toLowerCase() === "word") {
        splitChar = " ";
      }
      return this.message
        .split(splitChar)
        .map((item) => {
          const colors = [
            "hover:text-red-500",
            "hover:text-blue-500",
            "hover:text-yellow-500",
          ];
          const backgroundColors = [
            "hover:bg-red-500",
            "hover:bg-blue-500",
            "hover:bg-yellow-500",
          ];
          const shadows = [
            "shadow-red-500",
            "shadow-blue-500",
            "shadow-yellow-500",
          ];
          const randomNumber = Math.floor(
            Math.random() * backgroundColors.length
          );
          const randomColor = colors[randomNumber];
          const randomBackgroundColor = backgroundColors[randomNumber];
          const randomShadow = shadows[randomNumber];
          const magnify = this.magnify ? "hover:scale-150" : "";

          switch (this.type) {
            case "type1":
              return `<div class="${magnify} break-all inline-block ${randomColor} hover:m-0 m-0 p-0 transition-all duration-300 hover:duration-0 ease-in">${item}</div>`;
              break;
            case "type2":
              return `<span class="${magnify} break-all ${randomBackgroundColor} hover:text-white hover:m-px m-px transition-all duration-300 hover:duration-0 ease-in">${item}</span>`;
              break;
            case "type3":
              return `<span class="${magnify} break-all shadow-xl shadow-gray-900 hover:shadow-none hover:m-px m-px transition-all duration-500 hover:duration-0 ease-in">${item}</span>`;
              break;
            case "type4":
              return `<span class="${magnify} break-all shadow-xl ${randomShadow} hover:shadow-none hover:m-px m-px transition-all duration-500 hover:duration-0 ease-in">${item}</span>`;
              break;
            case "type5":
              return `<span class="${magnify} break-all ${randomBackgroundColor} hover:text-white shadow ${randomShadow} hover:shadow-gray-500 hover:m-px m-px transition-all duration-500 hover:duration-0 ease-in">${item}</span>`;
              break;
            default:
              break;
          }
        })
        .join("");
    },
  },
};
</script>

<style scoped></style>
