<template>
  <span id="typed" ref="sentence"></span>
</template>

<script>
import Typed from "typed.js";
export default {
  name: "TypedText",
  props: {
    sentences: {
      type: Array,
      default: () => {
        return ["Typed text"];
      },
    },
  },
  data() {
    return {
      options: {},
      typed: {},
    };
  },
  mounted() {
    this.options = {
      strings: this.sentences,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2500,
      loop: true,
      showCursor: true,
      // cursorChar: "",
      autoInsertCss: true,
    };

    this.typed = new Typed(this.$refs.sentence, this.options);
  },
};
</script>

<style scoped></style>
