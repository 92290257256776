<template>
  <div>
    <div
      class="text-2xl font-extrabold uppercase relative -left-[10px] mb-2 font-mono text-tertiary tracking-wider"
      v-if="$slots.heading"
    >
      <slot name="heading"></slot>
    </div>

    <div
      class="pl-6 relative border-l-4 border-l-tertiary space-y-2"
      :class="paddingBottom ? paddingBottom : ''"
    >
      <div
        class="inline-block absolute w-6 h-6 top-0 -left-[14px] text-tertiary bg-background1"
        v-if="$slots.icon"
      >
        <slot name="icon"></slot>
      </div>
      <div
        class="inline-block absolute w-6 h-6 top-0 -left-[14px] text-tertiary bg-background1 border-tertiary border-4"
        v-else="$slots.icon"
      ></div>

      <h1
        class="font-mono font-semibold text-xl leading-6 text-background2"
        v-if="$slots.subheading"
      >
        <slot name="subheading"></slot>
      </h1>
      <div
        class="bg-tertiary font-mono text-background1 px-3 py-1 inline-block text-sm tracking-tighter"
        v-if="$slots.timePeriod"
      >
        <slot name="timePeriod"></slot>
      </div>
      <div
        class="italic text-sm font-mono text-tertiary font-light"
        v-if="$slots.location"
      >
        <slot name="location"></slot>
      </div>

      <slot v-if="$slots.content" name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    paddingBottom: {
      type: String,
      default: "pb-0",
    },
  },
};
</script>

<style scoped></style>
