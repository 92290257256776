<template>
  <button
    :aria-label="`menu item -${$slots.default[0]['text']}`"
    class="group relative px-4 py-2 font-medium text-slate-100 transition-colors duration-[400ms] hover:text-background2"
  >
    <slot></slot>

    <!-- TOP -->
    <span
      class="absolute left-0 top-0 h-[2px] w-0 bg-background2 transition-all duration-100 group-hover:w-full"
    />

    <!-- RIGHT -->
    <span
      class="absolute right-0 top-0 h-0 w-[2px] bg-background2 transition-all delay-100 duration-100 group-hover:h-full"
    />

    <!-- BOTTOM -->
    <span
      class="absolute bottom-0 right-0 h-[2px] w-0 bg-background2 transition-all delay-200 duration-100 group-hover:w-full"
    />

    <!-- LEFT -->
    <span
      class="absolute bottom-0 left-0 h-0 w-[2px] bg-background2 transition-all delay-300 duration-100 group-hover:h-full"
    />
  </button>
</template>

<script>
export default {};
</script>

<style scoped></style>
