<template>
  <div class="absolute bottom-0 right-0 mr-2 mb-2 hidden font-mono" id="toast">
    <div
      :class="`max-w-xs bg-${toast.variant}-100 border border-${toast.variant}-200 text-sm text-${toast.variant}-800 rounded-md`"
      role="alert"
      tabindex="-1"
    >
      <div class="flex items-center p-4 gap-6">
        <div class="flex items-center gap-2">
          <div :class="`shrink-0 size-6 text-${toast.variant}-800`">
            <Icon :icon="toast.icon" width="100%" />
          </div>
          {{ toast.message }}
        </div>
        <div class="size-5">
          <button
            type="button"
            :class="`inline-flex shrink-0 justify-center items-center size-5 rounded-lg text-black opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100`"
            aria-label="Close"
            @click="hideToast"
          >
            <span class="sr-only">Close</span>
            <div class="text-black">
              <Icon icon="material-symbols:close-rounded" width="16" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      id="progressbar"
      :class="`absolute bottom-0 border-[3px] w-full border-${toast.variant}-600 `"
    ></div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import gsap from "gsap";

export default {
  name: "Toast",
  data() {
    return {
      toast: {
        message: "this is a toast message",
        variant: "danger",
        icon: "material-symbols:info-outline-rounded",
      },
      mytimeOut: "",
    };
  },
  components: {
    Icon,
  },
  mounted() {
    this.$root.$on("show-toast", this.showToast);
    this.$root.$on("hide-toast", this.hideToast);
  },
  methods: {
    showToast(toast) {
      if (this.mytimeOut) {
        clearTimeout(this.mytimeOut);
      }
      this.toast = toast;
      this.mytimeOut = setTimeout(() => {
        this.hideToast();
      }, toast.duration * 1000 || 3000);

      gsap.fromTo(
        "#progressbar",
        {
          width: "0%",
        },
        {
          width: "100%",
          duration: toast.duration || 3,
        }
      );
      gsap.fromTo(
        "#toast",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.3,
        }
      );
      gsap.fromTo(
        "#toast",
        {
          display: "hidden",
          y: -100,
        },
        {
          y: 0,
          display: "block",
          ease: "bounce.out",
          duration: 1,
        }
      );
    },
    hideToast() {
      if (this.mytimeOut) {
        clearTimeout(this.mytimeOut);
      }
      gsap.to("#toast", {
        display: "hidden",
        opacity: 0,
        duration: 0.5,
      });
    },
  },
};
</script>

<style></style>
