<template>
  <div class="bg-background2">
    <CustomCursor class="hidden sm:block" />
    <div class="max-w-screen-2xl m-auto">
      <div class="flex justify-center items-center h-screen">
        <div
          class="relative 2xl:h-[90%] h-full w-full bg-background1 shadow-2xl"
        >
          <router-view />
          <Toast />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCursor from "@/components/CustomCursor.vue";
import Toast from "@/components/Toast.vue";

export default {
  components: {
    CustomCursor,
    Toast,
  },
};
</script>

<style src="./assets/styles/app.css" />
