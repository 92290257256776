<template>
  <div>
    <MagicSentence
      :message="randomSentence"
      type="type1"
      apply="word"
      :magnify="true"
    />
  </div>
</template>

<script>
import MagicSentence from "@/components/MagicSentence.vue";

export default {
  data() {
    return {
      techWords: [
        // Frontend
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Vue.js",
        "Angular",
        "Bootstrap",
        "Tailwind CSS",
        "Sass",
        "LESS",
        "Webpack",
        "Parcel",
        "Gulp",
        "Grunt",
        "ES6",
        "TypeScript",
        "Babel",
        "PWA",
        "SSR",
        "SPA",
        "Responsive Design",
        "Media Queries",
        "Flexbox",
        "Grid",
        "CSS Modules",
        "Styled Components",
        "JAMstack",
        "GraphQL",
        "REST API",
        "AJAX",
        "JSON",
        "DOM",
        "Shadow DOM",
        "Web Components",
        "WebAssembly",
        "Canvas",
        "SVG",
        "Animations",
        "Transitions",
        "Accessibility",
        "ARIA",
        "SEO",
        "Lighthouse",
        "Web Vitals",
        "Service Workers",
        "IndexedDB",
        "LocalStorage",
        "SessionStorage",
        "Cookies",
        "OAuth",
        "JWT",
        "CORS",
        "Content Security Policy",
        "Cross-Browser",
        "Testing",
        "Mocha",
        "Jest",
        "Cypress",
        "Enzyme",
        "Storybook",
        "Webpack Dev Server",
        "Live Reloading",

        // Backend
        "Node.js",
        "Express",
        "Django",
        "Flask",
        "Ruby on Rails",
        "Spring Boot",
        "ASP.NET",
        "PHP",
        "Laravel",
        "Symfony",
        "Koa.js",
        "Fastify",
        "NestJS",
        "GraphQL",
        "REST",
        "Microservices",
        "Monolithic",
        "API Gateway",
        "Nginx",
        "Apache",
        "Load Balancer",
        "Reverse Proxy",
        "Docker",
        "Kubernetes",
        "Containerization",
        "Virtualization",
        "Vagrant",
        "Terraform",
        "Ansible",
        "Chef",
        "Puppet",
        "CI/CD",
        "Jenkins",
        "GitLab CI",
        "CircleCI",
        "Travis CI",
        "Build Automation",
        "Pipeline",
        "Testing",
        "Unit Testing",
        "Integration Testing",
        "End-to-End Testing",
        "Database",
        "SQL",
        "NoSQL",
        "PostgreSQL",
        "MySQL",
        "MongoDB",
        "Redis",
        "Elasticsearch",
        "Cassandra",
        "MariaDB",
        "Oracle DB",
        "SQLite",
        "Graph Database",
        "Neo4j",
        "Firestore",
        "Firebase",
        "ORM",
        "Sequelize",
        "TypeORM",
        "Mongoose",
        "Prisma",
        "Caching",
        "Memcached",
        "Message Queue",
        "RabbitMQ",
        "Kafka",
        "Socket.io",
        "WebSocket",
        "Serverless",
        "FaaS",
        "AWS Lambda",
        "Google Cloud Functions",
        "Azure Functions",
        "API Management",
        "Rate Limiting",
        "Throttling",
        "OAuth2",
        "OpenID Connect",
        "Identity Provider",
        "SSO",
        "LDAP",
        "Active Directory",
        "JWT",

        // DevOps
        "CI/CD",
        "Continuous Integration",
        "Continuous Delivery",
        "Continuous Deployment",
        "Infrastructure as Code",
        "IaC",
        "Configuration Management",
        "Docker",
        "Kubernetes",
        "Helm",
        "Terraform",
        "AWS",
        "GCP",
        "Azure",
        "CloudFormation",
        "Ansible",
        "Chef",
        "Puppet",
        "Vagrant",
        "GitOps",
        "Jenkins",
        "GitLab CI",
        "CircleCI",
        "Travis CI",
        "Monitoring",
        "Prometheus",
        "Grafana",
        "ELK Stack",
        "Logging",
        "Logstash",
        "Kibana",
        "Fluentd",
        "Graylog",
        "Alerting",
        "PagerDuty",
        "Datadog",
        "New Relic",
        "Splunk",
        "Nagios",
        "Zabbix",
        "Sentry",
        "Incident Management",
        "Infrastructure Monitoring",
        "Network Monitoring",
        "Application Performance Monitoring",
        "APM",
        "Service Mesh",
        "Istio",
        "Linkerd",
        "Consul",
        "Distributed Tracing",
        "Jaeger",
        "OpenTelemetry",
        "Security",
        "DevSecOps",
        "OWASP",
        "Container Security",
        "Kubernetes Security",
        "Secrets Management",
        "HashiCorp Vault",
        "Encryption",
        "TLS",
        "SSL",
        "PKI",
        "IAM",
        "RBAC",
        "ABAC",
        "Zero Trust",
        "Firewall",
        "WAF",
        "DDoS Protection",
        "Load Balancing",

        // AI
        "Artificial Intelligence",
        "Machine Learning",
        "Deep Learning",
        "Neural Networks",
        "NLP",
        "Computer Vision",
        "Reinforcement Learning",
        "Supervised Learning",
        "Unsupervised Learning",
        "Semi-supervised Learning",
        "Transfer Learning",
        "GANs",
        "Convolutional Neural Networks",
        "Recurrent Neural Networks",
        "LSTM",
        "BERT",
        "GPT",
        "Transformer",
        "Autoencoder",
        "Support Vector Machine",
        "K-Nearest Neighbors",
        "Decision Tree",
        "Random Forest",
        "Gradient Boosting",
        "XGBoost",
        "LightGBM",
        "CatBoost",
        "Clustering",
        "K-Means",
        "Hierarchical",
        "DBSCAN",
        "PCA",
        "t-SNE",
        "Data Preprocessing",
        "Feature Engineering",
        "Feature Selection",
        "Data Augmentation",
        "Hyperparameter Tuning",
        "Cross-Validation",
        "Model Evaluation",
        "Accuracy",
        "Precision",
        "Recall",
        "F1 Score",
        "ROC-AUC",
        "Confusion Matrix",
        "Overfitting",
        "Underfitting",
        "Regularization",
        "Dropout",
        "Batch Normalization",
        "Activation Function",
        "ReLU",
        "Sigmoid",
        "Tanh",
        "Softmax",
        "Gradient Descent",
        "Backpropagation",
        "Optimization",
        "Stochastic Gradient Descent",
        "Adam",
        "RMSprop",
        "Loss Function",
        "Cross Entropy",
        "MSE",
        "Hinge Loss",
        "Python",
        "TensorFlow",
        "Keras",
        "PyTorch",
        "Scikit-learn",
        "Pandas",
        "NumPy",
        "Matplotlib",
        "Seaborn",
        "OpenCV",
        "TensorBoard",
        "MLflow",
        "Model Deployment",
        "Model Monitoring",
        "Model Drift",
        "Data Drift",
        "Explainable AI",
        "SHAP",
        "LIME",
        "Bias",
        "Fairness",
        "Ethics in AI",
      ],
    };
  },
  components: {
    MagicSentence,
  },
  computed: {
    randomSentence() {
      return this.generateRandomSentence(this.techWords, 1500);
    },
  },
  methods: {
    generateRandomSentence(wordsArray, wordCount) {
      let sentence = "";
      for (let i = 0; i < wordCount; i++) {
        const randomIndex = Math.floor(Math.random() * wordsArray.length);
        sentence += `${wordsArray[randomIndex]} `;
      }
      return sentence;
    },
  },
};
</script>

<style scoped></style>
