import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import cursorMixin from "./mixins/cursor-mixin";

Vue.config.productionTip = false;

Vue.mixin(cursorMixin);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
