<template>
  <div>
    <div
      :style="cursorWrapperStyle"
      ref="cursorWrapper"
      class="cursor-wrapper bg-transparent w-10 h-10 z-10 border border-tertiary fixed top-0 left-0 rounded-full pointer-events-none -translate-x-[50%] -translate-y-[50%]"
    ></div>
    <div
      :style="cursorDotStyle"
      ref="cursorDot"
      class="cursor-dot w-4 h-4 z-10 rounded-full fixed bg-white pointer-events-none mix-blend-difference -translate-x-[50%] -translate-y-[50%]"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CustomCursor",
  data() {
    return {
      cursorDotStyle: {},
      cursorWrapperStyle: {},
    };
  },
  methods: {
    moveCursor(e) {
      const posX = e.clientX;
      const posY = e.clientY;

      try {
        this.$refs.cursorDot.style.left = `${posX}px`;
        this.$refs.cursorDot.style.top = `${posY}px`;

        setTimeout(() => {
          this.$refs.cursorWrapper.style.left = `${posX}px`;
          this.$refs.cursorWrapper.style.top = `${posY}px`;
        }, 75);
      } catch (error) {
        // console.log(error);
      }
    },

    changeCursorStyle(cursor) {
      this.cursorDotStyle = cursor.dot;
      this.cursorWrapperStyle = cursor.wrapper;
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.moveCursor);
    this.$root.$on("change-cursor-style", this.changeCursorStyle);
  },
};
</script>

<style></style>
